
import { Options, Vue } from "vue-class-component";
import { Profile } from "@/components/profile";

@Options({
  components: {
    Profile,
  },
})
export default class Content extends Vue {}
