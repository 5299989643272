import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CodeFalls = _resolveComponent("CodeFalls")!
  const _component_Background = _resolveComponent("Background")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_Foreground = _resolveComponent("Foreground")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Background, { class: "background" }, {
      default: _withCtx(() => [
        _createVNode(_component_CodeFalls)
      ]),
      _: 1
    }),
    _createVNode(_component_Layout, { class: "layout" }),
    _createVNode(_component_Foreground, { class: "foreground" })
  ], 64))
}